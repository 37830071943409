import React, { Component } from 'react';
import 'tailwindcss/tailwind.css';
import _ from 'lodash';
import Dashboard from './components/dashboard/layout';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children; 
  }
}

class App extends Component {

  constructor(props) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    const paramCode = params.get('code');
    if (paramCode) {
      localStorage.setItem('code', paramCode);
    }
    const code = paramCode || localStorage.getItem('code');
    if (this.requiresAuth()) {
      const auth = localStorage.getItem('refreshToken') || code;
      if (!auth) {
        this.redirectToLogin();
      }
    }
    this.state = {
      code,
      data : { apps: {} },
      days: params.get('days') || 4,
      sandbox: params.get('sandbox'),
      paramDate: params.get('date'),
      reportDate: params.get('date') || this.dateFormat(this.today()),
      websocketStatus : 'DISCONNECTED',
      lastPing : 0,
      isFetching: 0,
      editMode: false,
      hidden: JSON.parse(localStorage.getItem('hidden')) || {},
      nowrap: true,
      queryParams: params
    };
  }

  dateFormat = (date) => {
    if (!date) {
      return null;
    }
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return year + "-" + month + "-" + day;
  };

  dateToUse = () => {
    var day = this.today();
    if (this.state.paramDate) {
      const parsed = Date.parse(this.state.paramDate+"T00:00:00");
      day = new Date(parsed);
    }
    const tomorrow = new Date(day.setDate(day.getDate() + 1));
    return this.dateFormat(tomorrow);
  }

  today = () => {
    return new Date(new Date().toLocaleDateString("en-US", { timeZone: 'America/Los_Angeles' }));
  }

  fetchData = async (date = this.dateToUse(), days = 4) => {
    if (new Date().getTime() - (this.state.isFetching || 0) < 60000) {
      console.log("already fetching, skipping");
      return;
    }
    this.setState({isFetching: new Date().getTime()});
    const sandbox = this.state.queryParams.get('sandbox') || '';
    var refreshToken = localStorage.getItem('refreshToken');
    const authorization = refreshToken || this.state.code || '';
    const authType = refreshToken ? "refresh_token" : "authorization_code";
    const redir = encodeURIComponent(document.location.origin + "/");
    const api = `${this.apiURL()}/metrics?date=${date}&days=${days}&sandbox=${sandbox}&redir=${redir}`;
    const params = { 
      headers: { 
        'Authorization': authorization,
        'X-Authorization-Type': authType
      }
    };
    let response;
    try {
      response = await fetch(api, params);
      console.log('response', response);
      if ((response.status == 401 || response.status == 403) && !this.state.queryParams.get('error')) {
        if (refreshToken) {
          localStorage.removeItem('refreshToken');
        }
        return this.redirectToLogin();
      }
      else if (response.ok) {
        const dataJSON = await response.json();
        const data = _.merge(this.state.data, dataJSON);
        console.log('data', data);
        var dataDay = new Date(Date.parse(date+"T00:00:00"));
        const dataDate = new Date(dataDay.setDate(dataDay.getDate() - 1));
        console.log(...response.headers);
        const latestRefreshToken = response.headers.get("x-refresh-token");
        console.log('latestRefreshToken', latestRefreshToken);
        if (latestRefreshToken) {
          refreshToken = latestRefreshToken;
          localStorage.setItem('refreshToken', latestRefreshToken);
        }
        this.setState({ data, refreshToken, isFetching: 0, sandbox: sandbox, reportDate: this.dateFormat(dataDate) });
        console.log('state', this.state);
      }
    }
    catch (error) {
      console.error("error in fetch", error);
    }
  }

  setupWebsocket() {
    if (this.websocket) {
      this.websocket.close();
    }
    this.websocket = new WebSocket(this.websocketURL());
    this.setState({ websocketStatus : 'CONNECTING' });
    this.websocket.onmessage = (ev) => {
      console.log(ev.data);
      const json = JSON.parse(ev.data);
      if (json.refresh || (this.state.lastPing && new Date(this.state.lastPing).getDate() !== new Date().getDate())) {
        console.log('refreshing');
        this.fetchData(this.dateToUse(), 2);
      }
      this.setState({ websocketStatus : 'CONNECTED', lastPing : new Date().getTime() });
    };
    this.websocket.onerror = (error) => {
      console.error('error caught in websocket', error);
      this.setState({ websocketStatus : (this.websocket.readyState !== WebSocket.CLOSED && this.websocket.readyState !== WebSocket.CLOSING) ? 'CONNECTED' : 'DISCONNECTED' });
    };
    this.websocket.onopen = (ev) => {
      console.log('websocket open');
      const days = this.state.queryParams.get('days') || 4;
      this.setState({ websocketStatus : 'CONNECTED', lastPing : new Date().getTime(), days });
      this.fetchData(this.dateToUse(), days);
    };
  }

  isWebsocketConnected() {
    const connected = this.state.websocketStatus === 'CONNECTED' && (new Date().getTime() - (this.state.lastPing || 0)) < 120000;
    return connected;
  }

  checkWebsocket() {
    console.log('checkWebsocket', this.isWebsocketConnected());
    if (!this.isWebsocketConnected()) {
      this.setupWebsocket();
    }
  }

  setupDailyRefresh() {
    const millisToMidnight = new Date().setHours(24,0,0,0) - Date.now();
    console.log('refreshing in ' + millisToMidnight + 'ms');
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      console.log('daily refresh');
      this.fetchData(this.dateToUse(), 2);
      this.setupDailyRefresh();
    }, millisToMidnight);
  }

  componentDidMount () {
    console.log('componentDidMount');
    this.setupDailyRefresh();
    this.checkWebsocket();
    this.interval = setInterval(() => this.checkWebsocket(), 60000);
  }

  componentWillUnmount() {
    console.log('componentWillUnmount');
    if (this.websocket) {
      this.websocket.close();
    }
    this.websocket = null;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = null;
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = null;
    this.setState({ websocketStatus : 'DISCONNECTED', lastPing : 0, isFetching: 0 });
  }

  changeDays(days) {
    this.changeQueryParam('days', days);
  }

  changeDate(date) {
    if (!date || date === this.dateFormat(this.today())) {
      this.changeQueryParam('date', '');
    }
    else {
      this.changeQueryParam('date', date);
    }
  }

  changeSandbox(sandbox) {
    this.changeQueryParam('sandbox', sandbox);
  }

  changeQueryParam(key, value) {
    const url = new URL(window.location);
    url.searchParams.set(key, value);
    window.location = url.toString();
  }

  toggleEditMode() {
    this.setState({ editMode: !this.state.editMode });
  }

  toggleNoWrap() {
    this.setState({ nowrap: !this.state.nowrap });
  }

  toggleHidden(id) {
    const hidden = this.state.hidden[id];
    if (hidden) {
      delete this.state.hidden[id];
    }
    else {
      this.state.hidden[id] = 1;
    }
    localStorage.setItem('hidden', JSON.stringify(this.state.hidden));
    this.setState({ hidden: this.state.hidden });
  }

  apiURL() {
    const hostname = process.env.REACT_APP_HOSTNAME || document.location.hostname;
    return `https://${hostname}${process.env.REACT_APP_API_URL}`;
  }

  websocketURL() {
    const hostname = process.env.REACT_APP_HOSTNAME || document.location.hostname;
    return `wss://${hostname}${process.env.REACT_APP_WS_URL}`;
  }

  redirectToLogin() {
    const hostname = process.env.REACT_APP_HOSTNAME || document.location.hostname;
    document.location = `https://${hostname}${process.env.REACT_APP_LOGIN_URL}`;
  }

  logoutURL() {
    if (this.requiresAuth()) {
      const hostname = process.env.REACT_APP_HOSTNAME || document.location.hostname;
      return `https://${hostname}${process.env.REACT_APP_LOGOUT_URL}`;
    }
    return "";
  }

  requiresAuth() {
    return process.env.REACT_APP_AUTH === "true";
  }

  render() {
    return ( 
      <ErrorBoundary>
        <div className="bg-gray-100 dark:bg-slate-800 h-screen relative">
          <div className="flex items-start">
            <div className="flex flex-col h-screen pl-0 w-full lg:space-y-4 lg:w-100 overflow-auto pb-36 pt-8 px-2 md:pb-8 md:pt-4 md:px-8 lg:pt-0">
                <Dashboard title="Cromulent RealTime Reporting Dashboard" data={this.state.data} days={this.state.days} sandbox={this.state.sandbox} reportDate={this.state.reportDate} websocketStatus={this.state.websocketStatus} changeDays={this.changeDays.bind(this)} changeSandbox={this.changeSandbox.bind(this)} changeDate={this.changeDate.bind(this)} dateFormat={this.dateFormat} editMode={this.state.editMode} toggleEditMode={this.toggleEditMode.bind(this)} hidden={this.state.hidden} toggleHidden={this.toggleHidden.bind(this)} logoutURL={this.logoutURL()} nowrap={this.state.nowrap} toggleNoWrap={this.toggleNoWrap.bind(this)} />
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

export default App;
